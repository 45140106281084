import React, { useContext, useLayoutEffect } from 'react'
import axios from "axios";
import ls from "./ls";
import { AuthContext } from 'contexts';

const instance = axios.create()
instance.defaults.baseURL = process.env.REACT_APP_MAIN_API_URL;

instance.interceptors.request.use(
	async (config) => {
		const userInfo = ls.get("userInfo");
		const token = userInfo && userInfo.token;
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);

function MainInstance(props) {
	const {action} = useContext(AuthContext);

	useLayoutEffect(() => {
		instance.interceptors.response.use(
			function (response) {
				if(response.config.method === 'delete') {
					action.onSuccess('Амжилттай устгалаа!')
				}
				else if(response.config.method === 'post') {
					if(response.config.url === 'login'){
						action.onSuccess('Амжилттай нэвтэрлээ')
					}
					else if(response.config.url === 'forgotpassword'){
						action.onSuccess('Үйлдэл амжилттай!')
					}
					else{
						action.onSuccess('Үйлдэл амжилттай!')
					}
				}
				else if(response.config.method === 'put') {
					action.onSuccess('Амжилттай заслаа!')
				}
				else if(response.config.method === 'patch') {
					action.onSuccess('Үйлдэл амжилттай!')
				}
				return response;
			},
			function (error) {
				if(error.response) {
					if(error.response && error.response.status === 401){
						action.logout()
					}
					else if(error.response.data){
						if(error.response.data.value) {
							if(Array.isArray(error.response.data.value)) {
								action.onError(error.response.data.value[0].msg)
							}
							else {
								action.onError(error.response.data.value)
							}
						}
						else {
							action.onError('Үйлдэл амжилтгүй боллоо!')
						}
					}
				}
				return Promise.reject(error);
			}
		);
	},[])


	return (
		<>{props.children}</>
	)
}

export { instance }

export default MainInstance
