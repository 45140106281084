import {
  SettingOutlined,
  FileSearchOutlined,
  TrophyOutlined,
  PieChartOutlined,
  UserOutlined,
  PictureOutlined,
  UnorderedListOutlined,
  MailOutlined,
  BankOutlined,
  SolutionOutlined,
  TeamOutlined,
  BoldOutlined,
  SnippetsOutlined,
  FileTextOutlined,
  QuestionOutlined,
  ProfileOutlined,
  FileProtectOutlined
} from '@ant-design/icons';
import { MdOutlineSportsKabaddi, MdOutlineSportsBasketball } from "react-icons/md";
export default [
  {
    name: 'Хянах самбар',
    icon: <PieChartOutlined />,
    path: '/user/dashboard',
  },
  {
    name: 'Тэмцээн',
    icon: <TrophyOutlined />,
    path: '/user/event',
  },
  {
    name: 'Мэдээ',
    icon: <FileProtectOutlined />,
    path: '/user/news',
  },
  {
    name: 'Баннар',
    icon: <PictureOutlined />,
    path: '/user/banner',
  },
  {
    name: 'Журам',
    icon: <TrophyOutlined />,
    path: '/user/rule',
  },
  {
    name: 'Тохиргоо',
    type: 'sub',
    children: [
      {
        name: 'Ангилал',
        icon: <MdOutlineSportsBasketball />,
        path: '/user/category',
      },
    ]
  },
]
