export default ({ categoryType }) => {
    return [
        {
            label: 'Гарчиг',
            name: 'title',
            rules: [{required: true, message: 'Гарчиг оруулна уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Бүртгэл',
            name: 'status',
            type: 'select',
            rules: [{required: true, message: 'Бүртгэл сонгоно уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            options: [
                { label: 'Удахгүй болох', value: 2 },
                { label: 'Идэвхтэй', value: 1 },
                { label: 'Идэвхгүй', value: 0 },
            ],
            }
        },
        {
            label: 'Шагналын сангийн тайлбар',
            name: 'text',
            type: 'editor',
            className: 'col-span-12 mb-0',
            inputProps: {   
            className: 'w-full',
            }
        },
        {
            label: 'Мэдээлэл',
            name: 'information',
            type: 'textarea',
            rules: [{required: true, message: 'Мэдээлэл оруулна уу!'}],
            className: 'col-span-12 mb-0',
            inputProps: {   
            className: 'w-full',
            }
        },
        {
            label: 'Дэлгэрэнгүй',
            name: 'description',
            type: 'textarea',
            rules: [{required: true, message: 'Дэлгэрэнгүй оруулна уу!'}],
            className: 'col-span-12 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Эхлэх огноо',
            name: 'sDate',
            type: 'date',
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Зураг',
            name: 'image',
            type: 'file',
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Баннер',
            name: 'banner',
            type: 'file',
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Мобайл Баннер',
            name: 'mobileBanner',
            type: 'file',
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        ]
    }