import { Button, Form as AntForm, Card, Descriptions, Tag, Table, Divider, Modal, Input, Space, List, Dropdown, Menu, Tabs, Upload, Row, Col, Radio } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { PrinterOutlined, LeftOutlined, PlusOutlined, DownloadOutlined, CopyOutlined, EditOutlined, ToolOutlined, SearchOutlined ,MessageOutlined, LoadingOutlined, TrophyFilled, ClearOutlined, WarningOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { instance } from 'utils/axios';
import { Form } from 'components';
import HorseForm from './HorseForm';
import BuhForm from './BuhForm';
import BuhConnectForm from './BuhConnectForm';
import TabFields from './TabForm';
import TabHorseFields from './TabHorseForm';
import CopyFields from './CopyForm';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ExcelRender from './components/ExcelRender';
import buhImg from '../../../assets/images/buh.png';
import Workbook from 'react-excel-workbook';


function EventDetail() {
  const [ data, setData ] = useState(null);
  const [ tabData, setTabData ] = useState(null);
  const [ newTabData, setnewTabData ] = useState(null);
  const [ tabBetData, setTabBetData ] = useState(null);
  const [ tabBetActiveData, setTabBetActiveData ] = useState(null);
  const [ tabHorseData, setTabHorseData ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ isPartiEdit, setIsPartiEdit ] = useState(true);
  const [ isCopyEdit, setIsCopyEdit ] = useState(true);
  const [ isTabEdit, setIsTabEdit ] = useState(true);
  const [ openModal, setOpenModal ] = useState(false);
  const [ openModalFileEdit, setOpenModalFileEdit ] = useState(false);
  const [ openTabModal, setOpenTabModal ] = useState(false);
  const [ openPartiModal, setOpenPartiModal ] = useState(false);
  const [ openCopyModal, setOpenCopyModal ] = useState(false);
  const [ selectedData, setSelectedData ] = useState(null);
  const [ currentKey, setCurrentKey ] = useState(null);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ currentPageSelectedDetail, setCurrentPageSelectedDetail ] = useState(0);
  const [ currentPageBet, setCurrentPageBet ] = useState(0);
  const [ currentPageActiveBet, setCurrentPageActiveBet ] = useState(0);
  const [ currentPageHorse, setCurrentPageHorse ] = useState(0);
  const [ partiType, setPartiType ] = useState([]);
  const [ parti, setParti ] = useState([]);
  const [ tabType, setTabType ] = useState([]);
  const [ modal, contextHolder] = Modal.useModal();
  const [ header, setHeader ] = useState([]);
  const [ cols, setCols ] = useState([]);
  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const [ selectedDetailData, setSelectedDetailData ] = useState([]);
  const [searchText, setSearchText] = useState(''); //
  const [searchedColumn, setSearchedColumn] = useState(''); //
  const searchInput = useRef(null);  //
  const [ form ] = AntForm.useForm();
  const [ searchForm ] = AntForm.useForm();
  const {id} = useParams();
  const navigate = useNavigate();
  const mapStatusValue = (text) => (text === 1 ? 'Идэвхтэй' : 'Идэвхгүй');
  
  useEffect(() => {
    fetchData();
  },[]);

  useEffect(() => {
    setCurrentPage(0);
    setCurrentPageBet(0);
    setCurrentPageActiveBet(0);
  }, [currentKey]);

  useEffect(() => {
    fetchSelectedData();
  },[selectedKeys]);

  useEffect(() => {
    fetchTabData();
  },[currentKey]);

  useEffect(() => {
    fetchTabBetData();
  },[currentKey, currentPageBet]);

  useEffect(() => {
    fetchTabBetActiveData();
  },[currentKey, currentPageActiveBet]);

  useEffect(() => {
    fetchTabHorseData();
  },[currentKey, currentPageHorse]);


  const fetchData = () => {
    instance({
      method: 'get',
      url: `match/${id}`,
    }).then((res) => {
      setLoading(true);
      setData(res.data);
      let tmp = [];
      res.data.match_categories.map(((item) => {
      tmp.push({label: item.name, value: item.id })
      }));
      setTabType(tmp);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };
  const fetchSelectedData = () => {
    if(selectedKeys.length > 0){
      instance({
        method: 'get',
        url: `match/participantsearch/${currentKey && currentKey?.id}/${selectedKeys}`,
      }).then((res) => {
        setSelectedDetailData(res.data);
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  };

const fetchTabData = () => {
  if (currentKey && currentKey?.id) {
    setLoading(true);

    instance({
      method: 'get',
      url: `match/category/${currentKey.id}`,
    })
      .then((res) => {
        const newData = {
          count: res?.data?.count || 0,
          rows: res?.data?.rows || [],
        };
        setLoading(true);
        setnewTabData(newData);
        setTabData(res.data);
      })
      .catch((err) => {
        console.error('Error fetching tab data:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

  const fetchTabBetData = () => {
      instance({
        method: 'get',
        url: `match/betall/${currentKey ? currentKey.id : 0}/${currentPageBet}`,
      }).then((res) => {
        setLoading(true);
        setTabBetData(res.data);
        res.data.rows.map((item, i) => {
          item.key = i + 1;
          item.bets.map((child, idx) => {
            child.key = parseInt(`${i + 1}${idx + 1}`);
          });
        });
      }).catch((err) => {
      }).then(() => setLoading(false))
  };

  const fetchTabBetActiveData = () => {
      instance({
        method: 'get',
        url: `match/bet/${currentKey ? currentKey.id : 0}/${currentPageActiveBet}`,
      }).then((res) => {
        setLoading(true);
        setTabBetActiveData(res.data);
        res.data.rows.map((item, i) => {
          item.key = i + 1;
          item.bets.map((child, idx) => {
            child.key = parseInt(`${i + 1}${idx + 1}`);
          });
        });
      }).catch((err) => {
      }).then(() => setLoading(false))
  };

  const fetchTabHorseData = () => {
    if(data?.type == 1){
      instance({
        method: 'get',
        url: `match/horse/${currentKey ? currentKey.id : 0}/${currentPageHorse}`,
      }).then((res) => {
        setLoading(true);
        setTabHorseData(res.data);
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  };

  const renderComponent = (key) => {
    switch (key) {
      case key: return  <>
        {
          key ? <div>
            <Divider>{data?.type == 0 ? "Холбогдсон оролцогчид" : "" }</Divider>
            <div>
              {
                  key.rows && key.rows.map((foo, index) => (
                  <div key={`card-connect-${index}`}>
                  {
                    foo.child &&
                    <div className='flex gap-4 mb-4'>
                      <div className='w-1/2 flex border rounded-md p-4'>
                        <div className='w-1/4'>
                        {
                          foo.image.slice(1, 7) === "assets" ?
                          <img src={`${process.env.REACT_APP_CDN_URL}${foo.image}`} className='w-[100px]' alt={`event-${index}`}/>
                          : foo.image === null ?
                          <img src={buhImg} className='w-[100px]' alt={`null-${index}`}/>
                          : <img src={foo.image} className='w-[100px]' alt={`event-${index}`}/>
                        }
                        <div className='flex justify-start mt-2'>
                          <Button size='small' onClick={() => handlePartiEdit(foo)}>Засах</Button>
                        </div>
                        </div>
                        <div className='w-3/4'>
                          <Descriptions size='small' column={4} bordered labelStyle={{color: "#bbb"}} layout="vertical">
                            <Descriptions.Item label="Гарчиг">{foo.rank}</Descriptions.Item>
                            <Descriptions.Item label="Ангилал">{foo.name}</Descriptions.Item>
                            <Descriptions.Item label="Аймаг">{foo.province}</Descriptions.Item>
                            <Descriptions.Item label="Odd">{foo.odd}</Descriptions.Item>
                          </Descriptions>
                        </div>
                      </div>
                      <div className='w-1/2 flex border rounded-md p-4'>
                        <div className='w-1/4'>
                        {
                          foo.image.slice(1, 7) === "assets" ?
                          <img src={`${process.env.REACT_APP_CDN_URL}${foo.child.image}`} className='w-[100px]' alt={`event-${index}`}/>
                          : foo.child.image === null ?
                          <img src={buhImg} className='w-[100px]' alt={`null-${index}`}/>
                          : <img src={foo.child.image} className='w-[100px]' alt={`event-${index}`}/>
                        }
                        <div className='flex justify-start mt-2'>
                          <Button size='small' onClick={() => handlePartiEdit(foo.child)}>Засах</Button>
                        </div>
                        </div>
                        <div className='w-3/4'>
                          <Descriptions size='small' column={4} bordered labelStyle={{color: "#bbb"}} layout="vertical">
                            <Descriptions.Item label="Гарчиг">{foo.child.rank}</Descriptions.Item>
                            <Descriptions.Item label="Ангилал">{foo.child.name}</Descriptions.Item>
                            <Descriptions.Item label="Аймаг">{foo.child.province}</Descriptions.Item>
                            <Descriptions.Item label="Odd">{foo.child.odd}</Descriptions.Item>
                          </Descriptions>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                ))}
            </div>
            <Divider>{data.type == 0 ? "Оролцогчид" : "Морь"}</Divider>
            <div className='flex justify-end mb-4 gap-2'>
                <Button
                size='middle'
                type='primary'
                className='flex items-center border-green-500 hover:border-green-400 bg-green-500 hover:bg-green-400'
                icon={<ToolOutlined  className='text-base'/>}
                onClick={handleOpenModalFileEdit}
              >Excel засах</Button>
              <Button
                size='middle'
                type='primary'
                className='flex items-center border-green-500 hover:border-green-400 bg-green-500 hover:bg-green-400'
                icon={<PrinterOutlined className='text-base'/>}
                onClick={handleOpenFile}
              >Excel оруулах</Button>
                {newTabData?.rows?.length > 0 ? (
              <Workbook filename="Оролцогч.xlsx"
              element={<Button
              size='middle'
              type='primary'
              className='flex items-center border-green-500 hover:border-green-400 bg-green-500 hover:bg-green-400'
              icon={<DownloadOutlined className='text-base'/>}
              >
              Excel татах
              </Button>}>
                <Workbook.Sheet data={newTabData?.rows} name="Participants">
                  <Workbook.Column label="id" value="id" />
                  <Workbook.Column label="name" value="name" />
                  <Workbook.Column label="rank" value="rank" />
                  <Workbook.Column label="province" value="province" />
                  <Workbook.Column label="status" value={(row) => mapStatusValue(row?.status)} />
                </Workbook.Sheet>
              </Workbook>
                ) : (
                  <p className='flex items-center p-1 border rounded-lg border-grey-500'>
                    Data байхгүй
                  </p>
                )}
                  <Button
                size='middle'
                type='primary'
                className='flex items-center'
                icon={<CopyOutlined className='text-base'/>}
                onClick={() => handleAddCopyNew(key)}
              >Хуулах</Button>
              <Button
                size='middle'
                type='primary'
                className='flex items-center'
                icon={<PlusOutlined className='text-base'/>}
                onClick={handleAddPartiNew}
              >Нэмэх</Button>
              <Button
                size='middle'
                type='primary'
                className='flex items-center'
                icon={<ClearOutlined className='text-base'/>}
                onClick={() => setSelectedKeys([])}
              >Цэвэрлэх</Button>
            </div>
            <div>
              <Table
                size="small"
                rowKey={(row) => row.id}
                dataSource={key.rows}
                columns={buhColumns}
                loading={loading}
                scroll={{
                  y: 400,
                }}
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys: selectedKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedKeys(selectedRowKeys);
                    }
                }}
                pagination={{
                  defaultPageSize: 20,
                  onChange: (e) => setCurrentPage((e-1)*20),
                  showSizeChanger: key.row?.length > 20,
                  total: key.count,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200', '500'],
                  // current: currentPage + 1,
                  // total: key.count,
                  // onChange: (page) => {
                  //   console.log(page)
                  //   setCurrentPage(page-1)},
                  //   showSizeChanger: key.rows.length > 10,
                }}
              />
            </div>
            <Divider>Оролцогчид дээр таавар тависан хүмүүс</Divider>
            <Table
              size="small"
              rowKey={(row) => row.key}
              dataSource={selectedKeys.length > 0 ? selectedDetailData : []}
              columns={selectedDetailColumns}
              loading={loading}
              pagination={{
                defaultPageSize: 20,
                onChange: (e) => setCurrentPageSelectedDetail((e-1)*20),
                showSizeChanger: key.row?.length > 20,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
              }}
            />
            <Divider>Бооцоо (Бүгд)</Divider>
            <Table
              size="small"
              rowKey={(row) => row.id}
              dataSource={key ? tabBetData?.rows : currentKey}
              columns={columns}
              loading={loading}
              pagination={{
                total: tabBetData?.count,
                onChange: (page) => setCurrentPageBet(page - 1),
                showSizeChanger: tabBetData?.rows.length > 10
              }}
                expandable={{
                  expandedRowRender: (row) => (
                    <div>
                      <List
                        bordered
                        dataSource={row.bet_details}
                        renderItem={(item, i) => (
                          <List.Item key={i}>
                            <div className='flex flex-row gap-4'>
                              <div><div className='text-gray-600'>Ялах дараалал</div><div>{item?.order}</div></div>
                              <div>
                                {item.participant?.type === 1 ?
                                  <>
                                    <div className='text-gray-600'>Дугаар</div><div>{item.participant?.no}</div>
                                  </>
                                  :
                                  <>
                                    <div className='text-gray-600 w-[220px]'>Нэр</div><div>{item.participant?.name}</div>
                                  </>
                                }
                              </div>
                              <div><div className='text-gray-600'>Odd</div><div>{item?.odd}</div></div>
                            </div>
                            <div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                  ),
                  rowExpandable: (data) => data !== 'Дата алга',
                }}
            />


          </div>
          : null
        }
      </>
    }
  };
  // search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Хайх
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Арилгах
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Хаах
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const moriColumns = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{currentPage * 10 + i + 1}</span>,
    },
    {
      title: 'Зураг',
      dataIndex: 'image',
      key: 'image',
      render: (text, row, i) => (
        <>
          {
            text?.slice(1, 7) === "assets" ?
            <img src={`${process.env.REACT_APP_CDN_URL}${text}`} width={60} alt={`parti-${i}`}/>
            : text === null ?
            <img src={buhImg} width={60} alt={`null-${i}`}/>
            : <img src={text} width={60} alt={`parti-${i}`}/>
          }
        </>
        )
    },
    {
      title: 'Дугаар',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Зүс',
      dataIndex: 'complexion',
      key: 'complexion',
    },
    {
      title: 'Уяач',
      dataIndex: 'tier',
      key: 'tier',
    },
    {
      title: 'Угшил',
      dataIndex: 'strain',
      key: 'strain',
    },
    {
      title: 'Эзэмшигч',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'Аймаг',
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: 'Төрөл',
      dataIndex: 'type',
      key: 'type',
      render : (text, row) => <div>{text === 0 ? 'Шөвгийн найм' : text === 1 ? 'Үзүүр бөх' : text === 2 ? 'Түрүү бөх' : text === 3 ? 'Айргийн морь' : text === 4 ? 'Түрүү магнай' : null}</div>
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Button className='flex items-center text-xs' size='small' onClick={() => handlePartiEdit(row)}>
        <EditOutlined/> Засах
      </Button>
      )
    },
  ];
  const handleHorseColumns = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{currentPage * 10 + i + 1}</span>,
    },
    {
      title: 'Зүс',
      dataIndex: 'complexion',
      key: 'complexion',
    },
    {
      title: 'Уяач',
      dataIndex: 'tier',
      key: 'tier',
    },
    {
      title: 'Эзэмшигч',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'Аймаг',
      dataIndex: 'province',
      key: 'province',
    },
    // {
    //   title: 'Төрөл',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render : (text, row) => <div>{text === 0 ? 'Шөвгийн найм' : text === 1 ? 'Үзүүр бөх' : text === 2 ? 'Түрүү бөх' : text === 3 ? 'Айргийн морь' : text === 4 ? 'Түрүү магнай' : null}</div>
    // },
    // {
    //   title: 'Үйлдэл',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 80,
    //   render: (text, row) => (
    //     <Button className='flex items-center text-xs' size='small' onClick={() => handlePartiEdit(row)}>
    //     <EditOutlined/> Засах
    //   </Button>
    //   )
    // },
  ];

  const buhColumns = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: 'Зураг',
      dataIndex: 'image',
      key: 'image',
      render: (text, row, i) => (
        <>
          {
            text.slice(1, 7) === "assets" ?
            <img src={`${process.env.REACT_APP_CDN_URL}${text}`} width={60} alt={`parti-${i}`}/>
            : text === null ?
            <img src={buhImg} width={60} alt={`null-${i}`}/>
            : <img src={text} width={60} alt={`parti-${i}`}/>
          }
        </>
        )
    },
    {
      title: 'Нэр',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Цол',
      dataIndex: 'rank',
      key: 'rank',
      ...getColumnSearchProps('rank'),

    },
    {
      title: 'Аймаг',
      dataIndex: 'province',
      key: 'province',
      ...getColumnSearchProps('province'),

    },
    {
      title: 'Төлөв',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Tag color={text === 1 ? 'success' : 'default'}>
            {text === 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
        </Tag>
      ),
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Төрөл',
      dataIndex: 'type',
      key: 'type',
      render : (text, row) => <div>{text === 0 ? 'Шөвгийн найм' : text === 1 ? 'Үзүүр бөх' : text === 2 ? 'Түрүү бөх' : text === 3 ? 'Айргийн морь' : text === 4 ? 'Түрүү магнай' : null}</div>
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Button className='flex items-center text-xs' size='small' onClick={() => handlePartiEdit(row)}>
        <EditOutlined/> Засах
      </Button>
      )
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{currentPageBet * 10 + i + 1}</span>,
    },
    {
      title: 'Утас',
      dataIndex: 'player',
      key: 'player',
      ...getColumnSearchProps('player'),
      render: (text, row) => <div>{text?.phoneNumber}</div>,
      onFilter: (value, record) => record.player?.phoneNumber.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'BetId',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      render: (text, row) => <div>{text}</div>,
      onFilter: (value, record) => record.id?.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, row) => <div>{moment(text).format("YYYY-MM-DD hh:mm:ss")}</div>
    },
    {
      title: 'Дүн',
      dataIndex: 'player_invoices',
      key: 'player_invoices',
      render: (text, row) => <div>{text[0] ? text[0]?.amount.toLocaleString() : 0}₮</div>
    },
    {
      title: 'invoiceId',
      dataIndex: 'player_invoices',
      key: 'player_invoices',
      render: (text, row) => <div>{text[0]?.invoiceId}</div>
    },
    {
      title: 'Төлөв',
      dataIndex: 'player_invoices',
      key: 'player_invoices',
      render: (text, row) => <Tag color={text[0]?.status == 1 ? 'success': 'default'}>{text[0]?.status == 1 ? "Төлөгдсөн" : "Төлөгдөөгүй"}</Tag>,
      sorter: (a, b) => a.player_invoices[0]?.status - b.player_invoices[0]?.status,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Төрөл',
      dataIndex: 'type',
      key: 'type',
      render : (text, row) => <div>{text === 0 ? 'Шөвгийн найм' : text === 1 ? 'Үзүүр бөх' : text === 2 ? 'Түрүү бөх' : text === 3 ? 'Айргийн морь' : text === 4 ? 'Түрүү магнай' : null}</div>
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text, row) => (
        <Button className='flex items-center text-xs' size='small'
        onClick={() => handleBetAllSubmit(row.player_invoices?.[0].invoiceId)}
        >
        <EditOutlined/> Засах
      </Button>
      )
    },
  ];
  const selectedDetailColumns = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{currentPageSelectedDetail * 10 + i + 1}</span>,
    },
    {
      title: 'Сонгосон бөх',
      dataIndex: 'cnt',
      key: 'cnt',
      width:300,
      ...getColumnSearchProps('cnt'),
      onFilter: (value, record) => {
        const nameValue = record.cnt;
        return (
          nameValue !== undefined &&
          nameValue !== null &&
          nameValue.toString().toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: 'Нэр',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      onFilter: (value, record) => {
        const nameValue = record.name;
        return (
          nameValue !== undefined &&
          nameValue !== null &&
          nameValue.toString().toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: 'Утас',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
      onFilter: (value, record) =>
        record.phoneNumber !== undefined &&
        record.phoneNumber !== null &&
        record.phoneNumber.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Дүн',
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
      render: (text, row) => <div>{text ? text.toLocaleString() : 0}₮</div>
    },
  ];
  const ActiveColumns = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{currentPageActiveBet * 10 + i + 1}</span>,
    },
    {
      title: 'Утас',
      dataIndex: 'player',
      key: 'player',
      render: (text, row) => <div>{text?.phoneNumber}</div>,
    },
    {
      title: 'Үүсгэсэн  огноо',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, row) => <div>{moment(text).format("YYYY-MM-DD hh:mm:ss")}</div>
    },
    {
      title: 'Дүн',
      dataIndex: 'player_invoices',
      key: 'player_invoices',
      render: (text, row) => <div>{text[0] ? text[0]?.amount.toLocaleString() : 0}₮</div>
    },
    {
      title: 'invoiceId',
      dataIndex: 'player_invoices',
      key: 'player_invoices',
      render: (text, row) => <div>{text[0]?.invoiceId}</div>
    },
    {
      title: 'Төлөв',
      dataIndex: 'player_invoices',
      key: 'player_invoices',
      render: (text, row) => <Tag color={text[0]?.status == 1 ? 'success': 'error'}>{text[0]?.status == 1 ? "Төлөгдсөн" : "Төлөгдөөгүй"}</Tag>
    },
    {
      title: 'Төрөл',
      dataIndex: 'type',
      key: 'type',
      render : (text, row) => <div>{text === 0 ? 'Шөвгийн найм' : text === 1 ? 'Үзүүр бөх' : text === 2 ? 'Түрүү бөх' : text === 3 ? 'Айргийн морь' : text === 4 ? 'Түрүү магнай' : null}</div>
    },
  ];
  const handleTabSubmit = (values) => {
    setLoading(true);
    if(selectedData){
      instance({
        method: 'put',
        url: 'match/category',
        data: {
        ...values,
        id: selectedData.id,
        matchId: id,
        },
    }).then(() => {
        fetchData();
        handleCloseTabModal();
    }).catch((err) => {

    }).then(() => setLoading(false))
    } else {
      instance({
        method: 'post',
        url: 'match/category',
        data: {
        ...values,
        matchId: id,
        }
    }).then(() => {
        fetchData();
        handleCloseTabModal();
    }).catch((err) => {

    }).then(() => setLoading(false))
    }
  };
  const handleAddTabNew = () => {
    setSelectedData(null);
    setOpenTabModal(true);
  };
  const handleCloseTabModal = () => {
    setOpenTabModal(false);
    form.resetFields();
    setIsTabEdit(true);
  };
  const handleTabEdit = (foo) => {
    setOpenTabModal(true);
    setSelectedData(foo);
    setIsTabEdit(false);
  };

  const handlePartiSubmit = (values) => {
    console.log(values)
    setLoading(true);
    const formData = new FormData();
    let keys = Object.keys(values);
    keys?.map((item) => {
      formData.append(item, values[`${item}`] || '');
    });
    formData.set('image', values.image?.file ? values.image?.file : '');
    if (selectedData) {
      formData.append('id', selectedData.id);
      formData.append('mCategoryId', currentKey?.id);
      formData.append('type', currentKey?.type);
      formData.append('matchId', id);
      
      instance({
        method: 'put',
        url: 'participant',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
      }).then(() => {
        fetchTabData()
        setOpenPartiModal(false);
      }).catch((err) => {
      }).then(() => setLoading(false))
    } else {
      formData.append('mCategoryId', currentKey?.id);
      formData.append('matchId', id);
      formData.append('type', currentKey?.type);

      instance({
        method: 'post',
        url: 'participant',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
      }).then(() => {
        fetchData();
        setOpenPartiModal(false);
      }).catch((err) => {
      }).then(() => {
        setLoading(false)
        fetchTabData()
      })
    }
  };
  const handleAddPartiNew = () => {
    setOpenPartiModal(true);
    setSelectedData(null);
    form.resetFields(null);

  };
  const handlePartiEdit = (row) => {
    let tmp = []

    tabData?.rows?.filter(foo => foo.id !== row.id).map((foo, fooIndex) => {
      tmp.push({
        label: foo.name,
        value: foo.id
      })
    })
    console.log(tmp);
    setParti(tmp)

    setSelectedData(row);
    setIsPartiEdit(false);
    setOpenPartiModal(true);
  };

  const handleClosePartiModal = () => {
    setSelectedData(null);
    setOpenPartiModal(false);
    form.resetFields(null);
    setIsPartiEdit(true);
  };
  // betAll
  const handleBetAllSubmit = (values) => {
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Төлөв өөрчлөхөд итгэлтэй байна уу?',
      okText: 'Тийм',
      cancelText: 'Үгүй',
      onOk() {
        setLoading(true);
        return new Promise((resolve, reject) => {
          instance({
            method: 'put',
            url: 'match/betall',
            data: {
              id: String(values),
            },
          })
            .then(() => {
              fetchData();
              fetchTabBetData();
              handleCloseTabModal();
              resolve();
            })
            .catch((err) => {
              console.error('Error submitting bet:', err);
              reject();
            })
            .finally(() => setLoading(false));
        });
      },
      onCancel() {
      },
    });
  };


  const handleCopySubmit = (values) => {
    setLoading(true);
      instance({
          method: 'post',
          url: 'participant/copy',
          data: {
          ...values,
          }
      }).then(() => {
          fetchData();
          handleCloseCopyModal();
      }).catch((err) => {
      }).then(() => setLoading(false))
  };

  const handleAddCopyNew = (key) => {
    setOpenCopyModal(true);
    let tmp = [];
        key.rows.map(((item) => {
        tmp.push({label: item.name, value: item.id })
      }));
      setParti(tmp);
  };

  const handleCloseCopyModal = () => {
    setSelectedData(null);
    setOpenCopyModal(false);
    form.resetFields();
    setIsCopyEdit(true);
  };


    const handleFileSubmit = (values, method) => {
      let tmp = []
      cols.map((item, i) => {
        if(i !== 0){
          let el = item.filter((item) => !(item === 'Идэвхтэй' || item === 'Идэвхгүй'))
          let status = item.find((item) => item === 'Идэвхтэй' || item === 'Идэвхгүй')
          el.push(status === 'Идэвхтэй' ? 1 : 0)
          tmp.push({...el})
        }else{
          tmp.push({...item})
        }
        
      })
      tmp = tmp.filter(row => 
        Object.values(row).some(cell => cell !== null && cell !== "" && cell !== undefined && cell !== 0)
      );
      if(method ===  'post'){
        if(data?.type === 0){
          setLoading(true);
          instance({
            method: 'post',
            url: 'participant/bulk',
            data: {
              // data: cols.map(row => ({
              //   ...row,
              //   status: mapStatusValue(row?.status),
              // })),
              data: tmp,
              type: 0,
              mCategoryId: currentKey?.id,
              matchId: id,
            }
          }).then(() => {
            fetchData();
            handleCloseFileModal();
          }).catch((err) => {
          }).then(() => setLoading(false))
        } 
        else {
          setLoading(true)
          instance({
            method: 'post',
            url: 'participant/bulk',
            data: {
              data: tmp,
              // data: cols.map(row => ({
              //   ...row,
              //   status: mapStatusValue(row?.status),
              // })),
              type: 1,
              mCategoryId: currentKey?.id,
              matchId: id,
            },
          }).then(() => {
            fetchData();
            handleCloseFileModal();
          }).catch((err) => {
          }).then(() => setLoading(false))
        }
      }
      else {
        if(data?.type === 0){
          setLoading(true);
          instance({
            method: 'put',
            url: 'participant/bulk',
            data: {
              // data: cols.map(row => ({
              //   ...row,
              //   status: mapStatusValue(row?.status),
              // })),
              data: tmp,
              type: 0,
              mCategoryId: currentKey?.id,
              matchId: id,
            }
          }).then(() => {
            fetchData();
            handleOpenCloseModalFileEdit();
          }).catch((err) => {
          }).then(() => setLoading(false))
        }
        else {
          setLoading(true)
          instance({
            method: 'put',
            url: 'participant/bulk',
            data: {
              // data: cols.map(row => ({
              //   ...row,
              //   status: mapStatusValue(row?.status),
              // })),
              data: tmp,
              type: 1,
              mCategoryId: currentKey?.id,
              matchId: id,
            },
          }).then(() => {
            fetchData();
            handleOpenCloseModalFileEdit();
          }).catch((err) => {
          }).then(() => setLoading(false))
        }
      };
    };

    const handleOpenFile = () => {
      setOpenModal(true);
    };

    const handleCloseFileModal = () => {
      setOpenModal(false);
    };
    const handleOpenModalFileEdit = () => {
      setOpenModalFileEdit(true);
    };

    const handleOpenCloseModalFileEdit = () => {
      setOpenModalFileEdit(false);
    };

    const handleTabKey = (foo) => {
      setCurrentKey(foo);
      // let tmp = [];
      // foo.child.map(((item) => {
      //   tmp.push({label: item.name, value: item.id })
      // }));
      // setParti(tmp);
    };


    return (
    <div className='flex flex-col gap-6'>
      <div className='flex justify-start items-center gap-4 bg-white px-8 py-[13px]'>
        <button className='menu-button text-primary flex items-center hover:bg-slate-100 p-2 rounded-full' onClick={() => navigate(-1)}>
        <LeftOutlined />
        </button>
        <button className='menu-button text-primary'>
          Тэмцээний дэлгэрэнгүй
        </button>
      </div>
      <div className='mx-8'>
        <div className='flex justify-end mb-4'>
        </div>
        <Card className='flex flex-col'>
              <div className='pt-4'>
            <Descriptions size='small' bordered labelStyle={{color: "#bbb"}} column={4} title={"Ерөнхий мэдээлэл"} layout="vertical">
              <Descriptions.Item label="Гарчиг">{data?.title}</Descriptions.Item>
              <Descriptions.Item label="Тэмцээний огноо">{moment(data?.sDate).format('YYYY-MM-DD hh:mm:ss')}</Descriptions.Item>
              <Descriptions.Item label="Ангилал">{data?.category}</Descriptions.Item>
              <Descriptions.Item label="Бүртгэл"><Tag color={data?.status === 1 ? 'success' : 'default'}>{data?.status === 1 ? 'Идэвхтэй' : 'Идэвхгүй'}</Tag></Descriptions.Item>
              <Descriptions.Item span={4} label="Мэдээлэл">{data?.information}</Descriptions.Item>
              <Descriptions.Item span={4} label="Дэлгэрэнгүй">{data?.description}</Descriptions.Item>
              <Descriptions.Item span={4} label="Шагналын сангийн тайлбар">{data?.prizePool}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className='mt-6'>
            <div className='flex flex-col gap-2 mb-4'>
              <div className='flex justify-end'>
                <Button
                  size='middle'
                  type='primary'
                  className='flex items-center'
                  icon={<PlusOutlined className='text-base'/>}
                  onClick={handleAddTabNew}
                >Нэмэх</Button>
              </div>
              <div className='flex gap-4 items-center'>
                {
                  data?.match_categories && data?.match_categories.map((foo, index) => {
                  return (
                      <div className={` border border-b-0 rounded-md px-4 py-2`}>
                        <button className={`tab-button ${currentKey == foo && 'text-primary font-semibold'} cursor-pointer bg-white hover:text-blue-500`} onClick={() => handleTabKey(foo)}>{foo.name}</button>
                        <button className='cursor-pointer bg-white hover:text-blue-500' onClick={() => handleTabEdit(foo)} key={`tab-edit-${index}`}>
                              <EditOutlined />
                        </button>
                        <Link to={`/user/event/category/${foo.id}`}>
                        <button className={`ml-1 tab-button ${currentKey == foo && 'text-primary font-semibold'} cursor-pointer bg-white hover:text-blue-500`}>
                          <MessageOutlined/>
                        </button></Link>
                      </div>
                  )
                  })
                }
              </div>
            </div>
          </div>


          <Modal
            title={
              <div className="text-lg uppercase">
                {'Оролцогчид оруулах'}
              </div>
            }
            open={openModal}
            onCancel={handleCloseFileModal}
            width={1200}
            destroyOnClose
            footer={false}
          >
            <AntForm
              form={form}
              onFinish={(values) => handleFileSubmit(values, 'post')}
            >
              <AntForm.Item>
                  <ExcelRender header={header} setHeader={setHeader} cols={cols} setCols={setCols}/>
              </AntForm.Item>
              <AntForm.Item>
                <div className='flex justify-end'>
                  <Button type="primary" htmlType="submit">
                  Хадгалах
                  </Button>
              </div>
              </AntForm.Item>
            </AntForm>
          </Modal>

          <Modal
            title={
              <div className="text-lg uppercase">
                {'Оролцогчид засварлах'}
              </div>
            }
            open={openModalFileEdit}
            onCancel={handleOpenCloseModalFileEdit}
            width={1200}
            destroyOnClose
            footer={false}
          >
            <AntForm
              form={form}
              onFinish={(values) => handleFileSubmit(values, 'put')}
            >
              <AntForm.Item>
                  <ExcelRender header={header} setHeader={setHeader} cols={cols} setCols={setCols}/>
              </AntForm.Item>
              <AntForm.Item>
                <div className='flex justify-end'>
                  <Button type="primary" htmlType="submit">
                  Хадгалах
                  </Button>
              </div>
              </AntForm.Item>
            </AntForm>
          </Modal>


          <Modal
          title={
            <div className="text-lg uppercase">
              {'Оролцогч нэмэх'}
            </div>
          }
          open={openPartiModal}
          onCancel={handleClosePartiModal}
          width={900}
          destroyOnClose
          footer={false}
        >
          {
            data?.type === 1 ?
            <Form
              form={form}
              layout="vertical"
              onFinish={handlePartiSubmit}
              initialValues={[]}
              editData={selectedData && selectedData}
              isEdit={isPartiEdit}
              fields={HorseForm()}
              disabled={!isPartiEdit}
              className='gap-5'
            />
            :
            data?.type === 0 ?
            <>
              {
                selectedData ?
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handlePartiSubmit}
                  initialValues={[]}
                  editData={selectedData}
                  isEdit={isPartiEdit}
                  fields={BuhConnectForm({parti: parti})}
                  disabled={!isPartiEdit}
                  className='gap-5'
                />
                :
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handlePartiSubmit}
                  initialValues={[]}
                  editData={selectedData}
                  isEdit={isPartiEdit}
                  fields={BuhForm()}
                  disabled={!isPartiEdit}
                  className='gap-5'
                />
              }
            </>
            : null
          }
          {
            isPartiEdit ? (
            <div className='flex justify-end items-center gap-4 mt-5'>
              <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
              <Button onClick={() => setIsPartiEdit(false)}>Цуцлах</Button>
            </div>
            ) : (
            <div className='flex justify-end items-center gap-4 mt-5'>
              <Button
                type='primary'
                className='border-none bg-sent border-sent hover:bg-sent hover:brightness-110 flex items-center'
                onClick={() => setIsPartiEdit(true)}
              >
                <EditOutlined/>
                Засварлах
              </Button>
            </div>
          )}
          </Modal>
          <Modal
          title={
            <div className="text-lg uppercase">
              {'Оролцогч хуулах'}
            </div>
          }
          open={openCopyModal}
          onCancel={handleCloseCopyModal}
          width={900}
          destroyOnClose
          footer={false}
        >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleCopySubmit}
              initialValues={[]}
              isEdit={isCopyEdit}
              fields={CopyFields({ parti: parti, tabType: tabType})}
              disabled={!isCopyEdit}
              className='gap-5'
            />
          {
            isCopyEdit ? (
            <div className='flex justify-end items-center gap-4 mt-5'>
              <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
              <Button onClick={() => setIsCopyEdit(false)}>Цуцлах</Button>
            </div>
            ) : (
            <div className='flex justify-end items-center gap-4 mt-5'>
              <Button
                type='primary'
                className='border-none bg-sent border-sent hover:bg-sent hover:brightness-110 flex items-center'
                onClick={() => setIsCopyEdit(true)}
              >
                <EditOutlined/>
                Засварлах
              </Button>
            </div>
          )}
          </Modal>
          <Modal
          title={
            <div className="text-lg uppercase">
              {selectedData ? 'Таб засах' :'Таб нэмэх'}
            </div>
          }
          open={openTabModal}
          onCancel={handleCloseTabModal}
          width={900}
          destroyOnClose
          footer={false}
        >
          {
            data?.type == 0 ?
            <Form
            form={form}
            layout="vertical"
            onFinish={handleTabSubmit}
            initialValues={[]}
            editData={selectedData}
            isEdit={isTabEdit}
            fields={TabFields()}
            disabled={!isTabEdit}
            className='gap-5'
          /> :
          data?.type == 1 ?
          <Form
            form={form}
            layout="vertical"
            onFinish={handleTabSubmit}
            initialValues={[]}
            editData={selectedData}
            isEdit={isTabEdit}
            fields={TabHorseFields()}
            disabled={!isTabEdit}
            className='gap-5'
          /> : null
          }
          {
            isTabEdit ? (
            <div className='flex justify-end items-center gap-4 mt-5'>
              <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
              <Button onClick={() => setIsTabEdit(false)}>Цуцлах</Button>
            </div>
            ) : (
            <div className='flex justify-end items-center gap-4 mt-5'>
              <Button
                type='primary'
                className='border-none bg-sent border-sent hover:bg-sent hover:brightness-110 flex items-center'
                onClick={() => setIsTabEdit(true)}
              >
                <EditOutlined/>
                Засварлах
              </Button>
            </div>
          )}
          </Modal>
          {contextHolder}
          {renderComponent(tabData)}
        </Card>
      </div>
    </div>
  );
};

export default EventDetail;
