export default () => {
    return [
        {
            label: 'Төрөл',
            name: 'type',
            type: 'select',
            rules: [{ required: true, message: 'Төрөл сонгоно уу!' }],
            className: 'col-span-12 mb-0',
            inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Мобайл', value: true }, 
                    { label: 'Веб', value: false },   
                ],
            }
        },
        {
            label: 'Төлөв',
            name: 'isActive',
            type: 'select',
            rules: [{required: true, message: 'Төлөв сонгоно уу!'}],
            className: 'col-span-12 mb-0',
            inputProps: {
            className: 'w-full',
            options: [
                { label: 'Идэвхтэй', value: 1 },
                { label: 'Идэвхгүй', value: 0 },
            ],
            }
        },
        {
            label: 'Зураг',
            name: 'image',
            type: 'file',
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        ]
    }