import React from 'react';
import { Header, Footer, Sidebar } from './components';

const Layout = ({children}) => {
  return (
    <div id="layout">
      <Header></Header>
      <Footer></Footer>
      <Sidebar></Sidebar>
      <main>
        {children}
      </main>
    </div>
  );
}

export default Layout;
