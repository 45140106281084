    export default ({ parti }) => {
        return [
            {
                label: 'Аймаг',
                name: 'province',
                type: 'select',
                rules: [{required: true, message: 'Аймаг оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Архангай', value: 'Архангай' },
                    { label: 'Баян-өлгий', value: 'Баян-өлгий' },
                    { label: 'Баянхонгор', value: 'Баянхонгор' },
                    { label: 'Булган', value: 'Булган' },
                    { label: 'Говь-Алтай', value: 'Говь-Алтай' },
                    { label: 'Говьсүмбэр', value: 'Говьсүмбэр' },
                    { label: 'Дархан-Уул', value: 'Дархан-Уул' },
                    { label: 'Дундговь', value: 'Дундговь' },
                    { label: 'Дорноговь', value: 'Дорноговь' },
                    { label: 'Завхан', value: 'Завхан' },
                    { label: 'Орхон', value: 'Орхон' },
                    { label: 'Өвөрхангай', value: 'Өвөрхангай' },
                    { label: 'Өмнөговь', value: 'Өмнөговь' },
                    { label: 'Сүхбаатар', value: 'Сүхбаатар' },
                    { label: 'Сэлэнгэ', value: 'Сэлэнгэ' },
                    { label: 'Төв', value: 'Төв' },
                    { label: 'Увс', value: 'Увс' },
                    { label: 'Ховд', value: 'Ховд' },
                    { label: 'Хөвсгөл', value: 'Хөвсгөл' },
                    { label: 'Хэнтий', value: 'Хэнтий' },
                ]
                }
            },
            {
                label: 'Odd',
                name: 'odd',
                type: 'number',
                rules: [{required: true, message: 'Odd оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Нэр',
                name: 'name',
                rules: [{required: true, message: 'Нэр оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Цол',
                name: 'rank',
                type: 'select',
                rules: [{required: true, message: 'Цол оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                    options: [
                        { label: 'Дархан аварга', value: 'Дархан аварга' },
                        { label: 'Даян аварга', value: 'Даян аварга' },
                        { label: 'Улсын аварга', value: 'Улсын аварга' },
                        { label: 'Улсын арслан', value: 'Улсын арслан' },
                        { label: 'Улсын гарьд', value: 'Улсын гарьд' },
                        { label: 'Улсын заан', value: 'Улсын заан' },
                        { label: 'Улсын харцага', value: 'Улсын харцага' },
                        { label: 'Улсын начин', value: 'Улсын начин' },
                        { label: 'Аймгийн арслан', value: 'Аймгийн арслан' },
                        { label: 'Цэргийн арслан', value: 'Цэргийн арслан' },
                        { label: 'Аймгийн заан', value: 'Аймгийн заан' },
                        { label: 'Цэргийн заан', value: 'Цэргийн заан' }, 
                        { label: 'Аймгийн харцага', value: 'Аймгийн харцага' },
                        { label: 'Цэргийн харцага', value: 'Цэргийн харцага' },
                        { label: 'Аймгийн начин', value: 'Аймгийн начин' },
                        { label: 'Цэргийн начин', value: 'Цэргийн начин' },
                        { label: 'Сумын начин', value: 'Сумын начин' },
                        { label: 'Сумын заан', value: 'Сумын заан' },
                        { label: 'Сумын харцага', value: 'Сумын харцага' },
                        { label: 'Залуу бөх', value: 'Залуу бөх' },
                    ]
                }
            },
            {
                label: 'Гал',
                name: 'gal',
                rules: [{required: true, message: 'Гал оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Зураг',
                name: 'image',
                type: 'file',
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Оролцогч холбох',
                name: 'participantId',
                type: 'select',
                className: 'col-span-6 mb-0',
                inputProps: {
                className: 'w-full',
                options: [
                  {value: null, label: 'Цэвэрлэх'},
                  ...parti
                ],
                }
            },

            {
                label: 'Ялсан эсэх',
                name: 'winner',
                type: 'select',
                className: 'col-span-3 mb-0',
                inputProps: {
                    className: 'w-full',
                    options: [
                        { label: 'Ялсан', value: 1 },
                        { label: 'Ялагдсан', value: 0 },
                    ]
                }
            },
            {
                label: 'Төлөв',
                name: 'status',
                type: 'select',
                className: 'col-span-3 mb-0',
                inputProps: {
                  className: 'w-full',
                  options: [
                    { label: 'Идэвхтэй', value: 1 },
                    { label: 'Идэвхгүй', value: 0 },
                  ],
                },
            }
            ]
        }
