import React, { useState, useEffect, useContext } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { Button, Modal, Form as AntForm, Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';
import logo from 'assets/images/judag.png'
import { Form } from 'components';
import { instance } from 'utils/axios';
import ls from 'utils/ls';

const Header = ({}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const [ openModal, setOpenModal ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const [ form ] = AntForm.useForm()

  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  const handleLogout = () => {
    authContext.action.logout();
    ls.remove('userInfo')
    navigate("/user/login")
  }

  const fields = [
    {
      label: 'Хуучин нууц үг',
      name: 'oldpassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Хуучин нууц үг оруулна уу'}]
    },
    {
      label: 'Шинэ нууц үг',
      name: 'newpassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Шинэ нууц үг оруулна уу'}]
    },
    {
      label: 'Шинэ нууц үг давтах',
      name: 'confirmpassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Шинэ нууц үг давтан оруулна уу'}]
    },
  ]

  const handleSubmitChangePassword = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: 'changepassword',
      data: {
        oldpassword: values.oldpassword,
        newpassword: values.newpassword,
      }
    }).then(() => {
      handleCloseModal(false)
    }).catch((err) => {

    }).then(() => setLoading(false)) 
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    form.resetFields()
  }

  return (
    <header className="md:px-10">
      <div>
        <img src={logo} height={130} className='mx-auto'></img>
      </div>
      <div>
        <div className="relative flex items-center" onMouseOver={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
          {/* <div>{authContext.state.userInfo.profile.lastname} {authContext.state.userInfo.profile.firstname}</div> */}
          <Button type="text" className="group" size="large">
            <AiOutlineUser className="text-primary text-2xl"></AiOutlineUser>
          </Button>
          {
            dropdownOpen &&
            <div className="flex flex-col absolute right-0 bottom-0 py-2 rounded z-20 bg-white shadow-xl translate-y-full whitespace-nowrap" tabIndex={-1}>
              <Link className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0" onClick={() => setOpenModal(true)}>Нууц үг солих</Link>
              <Link className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0" onClick={handleLogout}>Гарах</Link>
            </div>
          }
        </div>
      </div>
      <Modal
        title={<div className='text-lg'>Нууц үг солих</div>}
        open={openModal} 
        onCancel={handleCloseModal}
        width={700}
        destroyOnClose  
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmitChangePassword}
          initialValues={[]}
          fields={fields}
          className='gap-5'
        />
        <div className='flex justify-end mt-5'>
          <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
        </div>
      </Modal>
    </header>
  );
}

export default Header;
