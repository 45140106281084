export default () => {
    return [
          {
        label: 'Нэр',
        name: 'name',
        className: 'col-span-12 mb-0',
        rules: [{required: true, message: 'Нэр оруулна уу!'}],
        inputProps: {
          className: 'w-full',
        }
      },
    ]
  }