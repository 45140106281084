export default ({ parti, tabType }) => {
    return [
        {
            label: 'Оролцогч',
            name: 'participantId',
            type: 'select',
            rules: [{required: true, message: 'Оролцогч оруулна уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            options: parti,
            }
        },
        {
            label: 'Төрөл',
            name: 'mCategoryId',
            type: 'select',
            rules: [{required: true, message: 'Төрөл сонгоно уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
                className: 'w-full',
                options: tabType,
            }
        },
        ]
    }