import React from 'react';
import actions from './actions';
import reducer from './reducer'
import ls from 'utils/ls';

const AuthContext = React.createContext()

const initialState = {
  loading: true,
  userInfo: null,
  token: null,
  tabIndexSetting: 0
}

const AuthProvider = ({children}) => {
  const [ state, dispatch ] = React.useReducer(reducer, initialState)
  const action = React.useMemo(() => actions(dispatch), [])

  React.useLayoutEffect(() => {
    const bootstrapAsync = async () => {
      let accessToken;
      try {
        var userInfo = ls.get('userInfo');
        var tabIndex = ls.get('tabIndexSetting');
        accessToken = userInfo && userInfo.token
        dispatch({ type: 'RESTORE_TOKEN', token: accessToken, userInfo: userInfo });
        if(tabIndex){
          dispatch({ type: 'CHANGE_TAB_INDEX_SETTING', index: tabIndex });
        }
      } catch(e) {
        console.log(e);
      }
    }

    bootstrapAsync();
  }, [])

  return (
    <AuthContext.Provider value={{state, action}}>
      {children}
    </AuthContext.Provider>
  );
}

export {
  AuthProvider,
  AuthContext
}
