import React, { useState, useEffect } from 'react';
import { Bar, Pie } from '@ant-design/plots';
import axios from 'axios';
import { Table } from 'antd';

const Dashboard = ({}) => {
  const [ data, setData] = useState([]);
  const [ totalData, setTotalData ] = useState([]);
  const [ taavarData, setTaavarData ] = useState(null);
  const [ tabData, setTabData ] = useState([]);
  const [ activeData, setActiveData ] = useState([]);
  const [ current4Page, setCurrent4Page ] = useState(0);
  const [ loading, setLoading ] = useState(false)
  const [ match, setMatch] = useState(null);
  const [ matchId, setMatchId] = useState(null);
  const [ matchData, setMatchData] = useState([]);
  const [ categoryId, setCategoryId] = useState(null);
  const [ categoryIndex, setCategoryIndex] = useState(null);
  useEffect(() => {
    fetchMatch();
  }, []);
  useEffect(() => {
    fetchPlayer();
    fetchTopTaavar();
  }, [categoryId]);

  useEffect(() => {
    fetchTotal()
  }, [matchId]);

  const fetchMatch = () => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_CDN_URL}/api/monitor/match`
    }).then(res => {
      setData(res.data)
    }).catch(err => {
      console.log(err);
    }).then(() => setLoading(false))
  };

  const fetchTopTaavar = () => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_CDN_URL}/api/monitor/match/category/${categoryId}`
    }).then(res => {
      setTaavarData(res.data)
    }).catch(err => {
      console.log(err);
    }).then(() => setLoading(false))
  };

  const fetchTotal= () => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_CDN_URL}/api/monitor/match/players/${matchId}`
    }).then(res => {
      setTotalData(res.data)
    }).catch(err => {
      console.log(err);
    }).then(() => setLoading(false))
  };

  const fetchPlayer = () => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_CDN_URL}/api/monitor/match/category/player/${categoryId}`
    }).then(async res => {
      let active = await res.data.filter((foo) => foo.status === 1)
      setTabData(res.data)
      // setAllData(res.data.map((foo) => ({value: foo.total, type: foo.name})))
      // setActiveData(active.map((foo) => ({value: foo.total, type: foo.name})))
    }).catch(err => {
      console.log(err);
    }).then(() => setLoading(false))
  };

  const configTab = {
    data: tabData,
    xField: 'name',
    yField: 'total',
    legend: {
      position: 'top-left',
    },
    meta: {
      name: {
        alias: 'Бөх',
      },
      total: {
        alias: 'Тоо',
      },
    },
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    scrollbar: {
      type: 'vertical',
    },
  };
  
  const pieConfigAll = {
    appendPadding: 10,
    data: totalData,
    angleField: 'total',
    colorField: 'name',
    radius: 0.8,
    label: {
      type: 'outer',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };
  const pieConfigTaavar = {
    appendPadding: 10,
    data: taavarData?.participants,
    angleField: 'total',
    colorField: 'name',
    radius: 0.8,
    label: {
      type: 'outer',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  };
  const columnTab = [
    {
      title: '',
      dataIndex: 'No',
      key: 'No',
      width: 50,
      render: (text, row, i) => <span>{current4Page + i + 1}</span>,
    },
    {
      title: 'Цол',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'Нэр',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Нутаг',
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: 'Нийт',
      dataIndex: 'total',
      key: 'total',
    },
  ]
  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex gap-4 p-3 bg-white border-gray-200 rounded-lg shadow">
      {
        data &&
        data.map((foo, fooIndex) => {
        return(
            <button key={`category-${fooIndex}`} className={`${match === fooIndex ? 'bg-[#EAF0FF] text-[#0B4EFF]' : 'bg-[#EAF0FF] text-[#626262] '} relative py-3 px-6 rounded-md text-sm font-semibold whitespace-nowrap cursor-pointer`} onClick={() => {setMatch(fooIndex); setMatchId(foo.id); setMatchData(foo.match_categories)}}>
                {foo.title}
            </button>
        )
      })
      }
      </div>
      {
        matchData.length > 0 &&
        <div className="flex gap-4 p-3 bg-white border-gray-200 rounded-lg shadow">
          {
            matchData.map((foo, fooIndex) => {
              return(
                  <button key={`category-${fooIndex}`} className={`${categoryIndex === fooIndex ? 'bg-[#EAF0FF] text-[#0B4EFF]' : 'bg-[#EAF0FF] text-[#626262] '} relative py-3 px-6 rounded-md text-sm font-semibold whitespace-nowrap cursor-pointer`} onClick={() => {setCategoryIndex(fooIndex); setCategoryId(foo.id)}}>
                      {foo.name}
                  </button>
              )
            })
          }
        </div>
      }
      {
        matchId &&
      <div className="p-6 bg-white border-gray-200 rounded-lg shadow">
        <div className='flex gap-6'>
          <div className='w-full'>
            <h1>
              Нийт бөх ({totalData?.length})
            </h1>
            <Pie loading={loading} {...pieConfigAll}></Pie>
          </div>
            <div className='w-full'>
              {
                categoryId &&
                <h1>
                {taavarData?.name} ({taavarData?.participants?.length})
              </h1>
              }
              <Pie loading={loading} {...pieConfigTaavar}></Pie>
            </div>
        </div>
      </div>
      }
      {
        categoryId && 
        <div className="p-6 bg-white border-gray-200 rounded-lg shadow">
          {/* <h1>
            Шөвгийн 4
          </h1> */}
          <div className='flex gap-6'>
            <div>
              <Bar loading={loading} {...configTab}></Bar>
            </div>
            <Table
              rowKey={(row) => row.key}
              loading={loading}
              className='w-full'
              dataSource={tabData}
              columns={columnTab}
              pagination={{
                defaultPageSize: 10,
                onChange: (e) => setCurrent4Page((e-1)*10),
                total: tabData.length,
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      }
    </div>
  );
}

export default Dashboard;
