import { Dashboard, Event, EventDetail, Category, Policy, News, Banner, MessengeSend, TestExcel } from 'modules/admin';

export default [
  {
    path: '/user/dashboard',
    element: <Dashboard />,
    name: 'Хянах самбар'
  },
  {
    path: '/user/category',
    element: <Category />,
  },
  {
    path: '/user/rule',
    element: <Policy />,
  },
  {
    path: '/user/event',
    element: <Event />,
  },
  {
    path: '/user/event/:id',
    element: <EventDetail />,
  },
  {
    path: '/user/event/category/:id',
    element: <MessengeSend />,
  },
  {
    path: '/user/news',
    element: <News />,
  },
  {
    path: '/user/banner',
    element: <Banner/>,
  },
  // {
  //   path: '/exceltest',
  //   element: <TestExcel/>,
  // },

]
