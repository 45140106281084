import { Select } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { instance } from 'utils/axios'

function ISelect(props) {
  const { dependentValue,  optionsUrl, setFieldValue, _field } = props
  const [ options, setOptions ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ init, setInit ] = useState(true)

  useEffect(() => {
    if(props.dependentValue){
      getOptions()
    }

    if(!init) {
      setFieldValue(_field.name, '')
    }
    else {
      setInit(false)
    }
  },[props.dependentValue])

  const getOptions = () => {
    setLoading(true)
    if(optionsUrl) {
      if(typeof optionsUrl === 'string') {
        instance({
          method: 'get',
          url: `${optionsUrl}/${dependentValue}`,
        }).then(res => {
          let tmp = []
          res.data.map((item,i) => {
            tmp.push({
              value: item.id,
              label: item.name,
            })
          })
          setOptions(tmp)
          
        }).catch(err => {
        }).then(() => {
          setLoading(false)
        })
      }
    }
  }

  return (
    <Select loading={loading} options={options} {...props}>
    </Select>
  )
}

export default ISelect