import Reacyarnt, { useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Form, Input, Collapse, Tabs, DatePicker, Select, Upload, Button, InputNumber, Divider, TreeSelect, Switch, Checkbox } from 'antd';
import ISelect from 'components/Select';
import Map from 'components/Map';
import TinyEditor from 'components/TinyEditor';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Panel } = Collapse

const CustomForm = ({fields, className, editData, ...restProps}) => {
  const [antForm] = Form.useForm()
  const form = restProps.form || antForm

  useEffect(() => {
    if(editData){
      form.setFieldsValue(editData)
      fields.map((item) => {
        if(item.type === 'date'){
          form.setFieldValue(item.name, moment(editData[item.name]))
        }
      })
    } 
  },[editData])

  const getFillPercent = (fields) => {
    let values = form.getFieldsValue()
    let filled = 0;
    let percent = 0
    if(fields){
      fields.map((field) => {
        if(values[`${field.name}`]){
          filled += 1
        }
      })
      percent = parseInt((filled * 100)/fields.length);
    }
    return `${percent} %`
  }

  const renderFormItem = (_field, _fieldIndex) => {
    switch (_field.type) {
      case 'collapse':
        return (
          <Collapse defaultActiveKey={['0']} key={`form-collapse-${_fieldIndex}`} {..._field} >
            {
              _field.children?.map((item, itemIndex) => (
                <Panel 
                  key={`${itemIndex}`} 
                  header={
                    <Form.Item className='mb-0' shouldUpdate={(pre, cur) => pre[item.name] !== cur[item.name]}>
                      <span>{item.label} - {getFillPercent(item.children)}</span>
                    </Form.Item>
                  }
                >
                  <div className="grid grid-cols-12 gap-4">
                    {
                      item.children && item.children.map((child, childIndex) => (
                        renderFormItem(child, childIndex)
                      ))
                    }
                  </div>
                </Panel>
              ))
            }
          </Collapse>
        );
      case 'tabs':
        return (
          <Tabs>

          </Tabs>
        )
      // case 'content':
      //   return (
      //     <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field} >
      //       <Editor style={{height: _field.inputProps?.height ? _field.inputProps?.height : '400px'}} {..._field.inputProps}/>
      //     </Form.Item>
      //   )
      case 'editor':
        return (
          // <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field} >
          <Form.Item name="content" {..._field}>
            <TinyEditor ditData={editData} name={_field.name} disabled={restProps.disabled} {..._field.inputProps} form={form} />
          </Form.Item>
          // </Form.Item>
        )
      case 'dependent':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} noStyle shouldUpdate={(preValue, curValue) => preValue[_field.dependentName] !== curValue[_field.dependentName]} className={_field.className} >
            { 
              ({getFieldValue}) => (
                <>
                {console.log('fieldValue', getFieldValue(_field.dependentName))}
                  {
                    getFieldValue(_field.dependentName) && 
                    (getFieldValue(_field.dependentName) == _field.dependentValue) ?
                    _field.children && _field.children.map((child, childIndex) => (
                      renderFormItem(child, childIndex)
                    )) 
                    :
                    null
                  }
                </>
              )
            }
          </Form.Item>
        )
      case 'date': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <DatePicker showTime={{
        format: 'HH:mm:ss',
      }}/>
          </Form.Item>
        )
      case 'select': 
        if(_field.dependentIndex) {
          return (
            <Form.Item noStyle shouldUpdate={(preValue, curValue) => preValue[_field.dependentIndex] !== curValue[_field.dependentIndex]} className={_field.className}>
              { ({getFieldValue, setFieldValue}) => {
                return (
                  <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
                    <ISelect
                      setFieldValue={setFieldValue}
                      getFieldValue={getFieldValue}
                      _field={_field}
                      optionsUrl={_field.inputProps.optionsUrl}
                      dependentValue={getFieldValue(_field.dependentIndex)}
                      placeholder={`${_field.label} сонгох`}
                      {..._field.inputProps}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          )
        }
        else {
          return (
            <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Select  {..._field.inputProps}/>
            </Form.Item>
          )
        }
      case 'treeSelect': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <TreeSelect  {..._field.inputProps}/>
          </Form.Item>
        )
      case 'map': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Map  {..._field.inputProps} value={form.getFieldValue(_field.name)} onChange={form.setFieldValue} disabled={restProps.disabled}/>
          </Form.Item>
        )
      case 'file': 
        return (
          <div className={`flex gap-5  items-center ${_field.className}`}>
            {editData && editData[_field.name] ? 
              <img className='max-w-[100px]' src={`${process.env.REACT_APP_CDN_URL}${editData[_field.name]}`}/>
              :
              null
            }
            <Form.Item valuePropName="file" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Upload beforeUpload maxCount={1} {..._field.inputProps}>
                  <Button icon={<UploadOutlined />}>Файл оруулах</Button>
                </Upload>
            </Form.Item>
          </div>
        )
      case 'listFile': 
        return (
          <Form.Item valuePropName="file" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Upload beforeUpload {..._field.inputProps}>
                <Button icon={<UploadOutlined />}>Файл оруулах</Button>
              </Upload>
          </Form.Item>
        )
      case 'password': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Input.Password />
          </Form.Item>
        )
      case 'switch': 
        return (
          <Form.Item valuePropName="checked" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Switch />
          </Form.Item>
        )
      case 'textarea': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Input.TextArea placeholder={`${_field.label} оруулах`} {..._field.inputProps} />
          </Form.Item>
        )
      case 'number': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <InputNumber placeholder={`${_field.label} оруулах`} {..._field.inputProps}/>
          </Form.Item>
        )
      case 'percent': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              placeholder={`${_field.label} оруулах`} {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            valuePropName="checked"
            {..._field}
            label=''
          >
            <Checkbox
              {..._field.inputProps}
            >
              {_field.label}
            </Checkbox>
          </Form.Item>
        );
      case 'component': 
        return (_field.component)
      case 'divider': 
        return (
          <Form.Item {..._field}>
            <Divider key={`divider-${_field.label}`} {..._field.inputProps} >{_field.text}</Divider> 
          </Form.Item>
        )
      default:
        return (
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            {..._field}>
            <Input placeholder={`${_field.label} оруулах`} {..._field.inputProps} />
          </Form.Item>
        )
    }
  }

  return (
    <Form
      className={`grid grid-cols-12 ${className}`}
      {...restProps}
      
      initialValues={{content: ''}}>
      {
        fields.map((item, itemIndex) => renderFormItem(item, itemIndex))
      }
    </Form>
  );
}

CustomForm.propTypes = {
  fields: PropTypes.array,
}

CustomForm.defaultProps = {
  fields: [],
}


export default CustomForm;
