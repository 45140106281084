import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({}) => {
  return (
    <footer className='flex items-center justify-end text-basegray text-xs px-5'>
      Powered by FractalTech
    </footer>
  );
}

export default Footer;
