import { Table, Button, Modal, Form as AntForm, Input, Upload, Menu, Dropdown, Tag} from 'antd'
import { useState, useEffect } from 'react'
import { PlusOutlined, MoreOutlined,  DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios'
import { Form } from 'components'
import fields from './form'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
function Event() {
    const [ data, setData ] = useState([])
    const [ openModal, setOpenModal ] = useState(false)
    const [ selectedData, setSelectedData ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ isEdit, setIsEdit ] = useState(true)
    const [ modal, contextHolder ] = Modal.useModal();
    const [ categoryType, setCategoryType ] = useState([])

    const navigate = useNavigate()
    const [ form ] = AntForm.useForm()
    useEffect(() => {
        fetchData()
        fetchCategoryType()
    },[])

    const fetchData = () => {
        setLoading(true)
        instance({
        method: 'get',
        url: 'match'
        }).then((res) => {
        setData(res.data)
        }).catch((err) => {
        }).then(() => setLoading(false))
    }
    const fetchCategoryType = () => {
        instance({
        method: 'get',
        url: 'category'
        }).then((res) => {
        let tmp = []
        res.data.map(((item) => {
            tmp.push({ label: item.name, value: item.id })
        }))
        setCategoryType(tmp)
        }).catch((err) => {})
    }
    const columns = [
        {
        title: '',
        dataIndex: 'No',
        key: 'No',
        width: 50,
        render: (text, row, i) => (
            <span>{i+1}</span>
        )
        },
        {
        title: 'Гарчиг',
        dataIndex: 'title',
        key: 'title',
        },
        {
        title: 'Ангилал',
        dataIndex: 'category',
        key: 'category',
        },
        {
        title: 'Бүртгэл',
        dataIndex: 'status',
        key: 'status',
        render: (text) => (
            <Tag color={text === 1 ? 'success' : text === 0 ? 'default' : text === 2 ? 'orange' : ''}>
                {text === 1 ? 'Идэвхтэй' : text === 0 ? 'Идэвхгүй' : text === 2 ? 'Удахгүй болох' : ''}
            </Tag>
        )
        },
        {
        title: 'Эхлэх огноо',
        dataIndex: 'sDate',
        key: 'sDate',
        render:(text, row) => (
            <div>{moment(text).format('YYYY-MM-DD')}</div>
        )
        },
        {
        title: 'Үйлдэл',
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render: (text, row) => (
            <div className='flex gap-2'>
            <Button className='flex items-center' onClick={() => navigate(`${row.id}`)}>
                Дэлгэрэнгүй
            </Button>
            <Dropdown
            placement="bottomRight"
            overlay={
                <Menu>
                    <Menu.Item onClick={() => handleEdit(row)} key="action-1">
                    <div className="flex items-center gap-2">
                        <EditOutlined /> Засах
                    </div>
                    </Menu.Item>
                    <Menu.Item
                    danger
                    className="flex items-center gap-2"
                    onClick={() => handleDelete(row.id)}
                    key="action-2" 
                    >
                    <div className="flex items-center gap-2">
                        <DeleteOutlined /> Устгах
                    </div>
                    </Menu.Item>
                </Menu>
                }
                trigger={['click']}
            >
                <Button className="flex items-center">
                <MoreOutlined />
                </Button>
            </Dropdown>            </div>
        )
        },
    ] 

    const handleDelete = (id) => {
        modal.confirm({
        icon: <WarningOutlined style={{color: 'red'}}/>,
        title: 'Анхаарна уу !',
        content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
        okText: 'Устгах',
        cancelText: 'Буцах',
        onOk() {
            return new Promise((resolve, reject) => {
            instance({
                method: 'delete',
                url: `match/${id}`
            }).then((res) => {
                resolve()
            }).catch((err) => {
                reject()
            }).then(() => fetchData())
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
        });
    }

    const handleSubmit = (values) => {
        setLoading(true)
        const formData = new FormData();
        let keys = Object.keys(values);
        keys.map((item) => {
        formData.append(item, values[`${item}`] || '');
        });
        formData.set('image', values.image?.file ? values.image?.file : '');
        formData.set('banner', values.banner?.file ? values.banner?.file : '');
        formData.set('mobileBanner', values.mobileBanner?.file ? values.mobileBanner?.file : '');
        formData.set('status', values.status);
        formData.append('categoryId', 1)
        formData.append('type', 0)
        if(selectedData){
        formData.append('id', selectedData.id)
            instance({
                method: 'put',
                url: 'match',
                headers: { "Content-Type": "multipart/form-data" },
                data: formData,
            }).then(() => {
                fetchData()
                handleCloseModal()
            }).catch((err) => {
                
            }).then(() => setLoading(false))
            }
            else{
            instance({
                method: 'post',
                url: 'match',
                headers: { "Content-Type": "multipart/form-data" },
                data: formData,
            }).then(() => {
                fetchData()
                handleCloseModal()
            }).catch((err) => {
                
            }).then(() => setLoading(false))
        }
    }

    const handleEdit = (row) => {
        setSelectedData(row)
        setIsEdit(false)
        setOpenModal(true)
    }

    const handleAddNew = () => {
        setSelectedData(null)
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        form.resetFields()
        setIsEdit(true)
    }

return (
        <div className='flex flex-col gap-6'>
        <div className='flex justify-between items-center bg-white px-8 py-4'>
            <button className='menu-button text-primary'>
                Тэмцээн
            </button>
        </div>
        <div className='mx-8'>
            <div className='flex justify-end gap-4 mb-5'>
            <Button 
                size='large' 
                type='primary' 
                className='flex items-center'
                icon={<PlusOutlined className='text-base'/>}
                onClick={handleAddNew}
            >
                Нэмэх
            </Button>
            </div>
            <Table
            rowKey={(row) => row.key}
            dataSource={data}
            columns={columns}
            loading={loading}
            />
            <Modal
                title={<div className='text-lg'>{selectedData ? 'Засах' : 'Нэмэх'}</div>}
                open={openModal} 
                onCancel={handleCloseModal}
                width={1000}
                destroyOnClose  
                footer={false}
            >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                editData={selectedData}
                fields={fields({
                    categoryType: categoryType,
                })}
                isEdit={isEdit}
                disabled={!isEdit}
                className='gap-5'
            />
            {
                selectedData ? 
                <div className='flex justify-end mt-5'>
                {
                    isEdit ?
                    <div className='flex items-center gap-4'>
                    <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
                    <Button onClick={() => setIsEdit(false)}>Цуцлах</Button>
                    </div>
                    :
                    <Button 
                    type='primary' 
                    className='border-none bg-sent border-sent hover:bg-sent hover:brightness-110 flex items-center' 
                    onClick={() => setIsEdit(true)}
                    >
                    <EditOutlined/>
                    Засварлах
                    </Button>
                }
            </div>
            :
            <div className='flex justify-end mt-5'>
                <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
            </div>
            }
            </Modal>
        </div>
        {
            contextHolder
        }
        </div>
    )
}

export default Event