    import { Input, Form, Select } from "antd"

    export default () => {
        return [
            {
                label: 'Аймаг',
                name: 'province',
                type: 'select',
                rules: [{required: true, message: 'Аймаг оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Архангай', value: 'Архангай' },
                    { label: 'Баян-өлгий', value: 'Баян-өлгий' },
                    { label: 'Баянхонгор', value: 'Баянхонгор' },
                    { label: 'Булган', value: 'Булган' },
                    { label: 'Говь-Алтай', value: 'Говь-Алтай' },
                    { label: 'Говьсүмбэр', value: 'Говьсүмбэр' },
                    { label: 'Дархан-Уул', value: 'Дархан-Уул' },
                    { label: 'Дорноговь', value: 'Дорноговь' },
                    { label: 'Завхан', value: 'Завхан' },
                    { label: 'Орхон', value: 'Орхон' },
                    { label: 'Өвөрхангай', value: 'Өвөрхангай' },
                    { label: 'Өмнөговь', value: 'Өмнөговь' },
                    { label: 'Сүхбаатар', value: 'Сүхбаатар' },
                    { label: 'Сэлэнгэ', value: 'Сэлэнгэ' },
                    { label: 'Төв', value: 'Төв' },
                    { label: 'Увс', value: 'Увс' },
                    { label: 'Ховд', value: 'Ховд' },
                    { label: 'Хөвсгөл', value: 'Хөвсгөл' },
                    { label: 'Хэнтий', value: 'Хэнтий' },
                ]
                }
            },
            {
                label: 'Odd',
                name: 'odd',
                type: 'number',
                rules: [{required: true, message: 'Odd оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Дугаар',
                name: 'no',
                type: 'number',
                rules: [{required: true, message: 'Дугаар оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Зүс',
                name: 'complexion',
                rules: [{required: true, message: 'Зүс оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Уяач',
                name: 'tier',
                rules: [{required: true, message: 'Уяач оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Угшил',
                name: 'strain',
                rules: [{required: true, message: 'Угшил оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Эзэмшигч',
                name: 'owner',
                rules: [{required: true, message: 'Эзэмшигч оруулна уу!'}],
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            {
                label: 'Зураг',
                name: 'image',
                type: 'file',
                className: 'col-span-6 mb-0',
                inputProps: {
                    className: 'w-full',
                }
            },
            // {
            //     label: 'Төрөл',
            //     name: 'type',
            //     type: 'select',
            //     rules: [{required: true, message: 'Төрөл оруулна уу!'}],
            //     className: 'col-span-6 mb-0',
            //     inputProps: {
            //     className: 'w-full',
            //     options: [
            //         { label: 'Морь', value: 1 },
            //         { label: 'Бөх', value: 0 },
            //     ],
            //     }
            // },
            // {
            //     type: 'component',
            //     component: <>
            //     <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
            //         {({ getFieldValue }) => {
            //             return (
            //                 <>
            //                     {
            //                         getFieldValue('type') === 1 ?
            //                             <>
            //                                 <Form.Item
            //                                     label="Дугаар"
            //                                     name="no"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Дугаар оруулна уу!'}]}
            //                                     >
            //                                         <Input type="number"/>
            //                                 </Form.Item>
            //                                 <Form.Item
            //                                     label="Зүс"
            //                                     name="complexion"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Зүс оруулна уу!'}]}
            //                                     >
            //                                         <Input/>
            //                                 </Form.Item>
            //                                 <Form.Item
            //                                     label="Уяач"
            //                                     name="tier"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Уяач оруулна уу!'}]}
            //                                     >
            //                                         <Input/>
            //                                 </Form.Item>
            //                                 <Form.Item
            //                                     label="Угшил"
            //                                     name="strain"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Угшил оруулна уу!'}]}
            //                                     >
            //                                         <Input/>
            //                                 </Form.Item>
            //                                 <Form.Item
            //                                     label="Эзэмшигч"
            //                                     name="owner"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Эзэмшигч оруулна уу!'}]}
            //                                     >
            //                                         <Input/>
            //                                 </Form.Item>
            //                             </>
            //                             :
            //                             getFieldValue('type') === 0 ?
            //                             <>
            //                                 <Form.Item
            //                                     label="Нэр"
            //                                     name="name"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Нэр оруулна уу!'}]}
            //                                     >
            //                                         <Input type="number"/>
            //                                 </Form.Item>
            //                                 <Form.Item
            //                                     label="Цол"
            //                                     name="rank"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Цол оруулна уу!'}]}
            //                                     >
            //                                         <Select  options={[
            //                                             { label: 'Дархан аварга', value: 'Дархан аварга' },
            //                                             { label: 'Даян аварга', value: 'Даян аварга' },
            //                                             { label: 'Далай аварга', value: 'Далай аварга' },
            //                                             { label: 'Улсын аварга', value: 'Улсын аварга' },
            //                                             { label: 'Улсын арслан', value: 'Улсын арслан' },
            //                                             { label: 'Улсын гарьд', value: 'Улсын гарьд' },
            //                                             { label: 'Улсын заан', value: 'Улсын заан' },
            //                                             { label: 'Улсын харцага', value: 'Улсын харцага' },
            //                                             { label: 'Улсын начин', value: 'Улсын начин' },
            //                                             { label: 'Аймгийн арслан', value: 'Аймгийн арслан' },
            //                                             { label: 'Цэргийн арслан', value: 'Цэргийн арслан' },
            //                                             { label: 'Аймгийн заан', value: 'Аймгийн заан' },
            //                                             { label: 'Аймгийн начин', value: 'Аймгийн начин' },
            //                                             { label: 'Сумын заан', value: 'Сумын заан' },
            //                                         ]}/>
            //                                 </Form.Item>
            //                                 <Form.Item
            //                                     label="Гал"
            //                                     name="gal"
            //                                     className="col-span-6 mb-0"
            //                                     rules={[{required: true, message: 'Гал оруулна уу!'}]}
            //                                     >
            //                                         <Input/>
            //                                 </Form.Item>
            //                             </>
            //                         : null
            //                     }
            //                 </>
            //             )
            //         }}
            //     </Form.Item>
            //     </>
            // },
            ]
        }