import React from 'react';
import PropTypes from 'prop-types';

const GridItem = ({data, columns, rowIndex}) => {

  return (
    <div className="card flex gap-10 py-4 px-10 justify-between">
      {columns.map((column, columnIndex) => {
        if(column.render) {
          return React.cloneElement(column.render(column.dataIndex ? data[column.dataIndex] : null, data), {
            key: `${rowIndex}-column-${column}-${columnIndex}`
          })
        }
        return (
          <span key={`${rowIndex}-column-${column}-${columnIndex}`}>
            {column.dataIndex ? typeof(data[column.dataIndex]) == "string" ? data[column.dataIndex] : 'object' : null}
          </span>
        )
      })}
    </div>
  );
}

GridItem.propTypes = {
  columns: PropTypes.array
}

GridItem.defaultProps = {
  columns: []
}


export default GridItem;
