export default () => {
    return [
        {
            label: 'Нэр',
            name: 'name',
            rules: [{required: true, message: 'Нэр оруулна уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Нийт сонгож болох тоо',
            name: 'pCount',
            type: 'number',
            rules: [{required: true, message: 'Нийт сонгож болох тоо оруулна уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
                className: 'w-full',
            }
        },
        {
            label: 'Шагналын сангийн тайлбар',
            name: 'question',
            type: 'textarea',
            className: 'col-span-12 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Төрөл',
            name: 'type',
            type: 'select',
            className: 'col-span-6 mb-0',
            rules: [{required: true, message: 'Төрөл сонгоно уу!'}],
            inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Морь', value: 3 },
                    { label: 'Морь(гараас)', value: 4 },
                ],
            }
        },
        {
            label: 'Бооцоо бүртгэх эсэх',
            name: 'status',
            type: 'select',
            rules: [{required: true, message: 'Бооцоо бүртгэх эсэх сонгоно уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Идэвхтэй', value: 1 },
                    { label: 'Идэвхгүй', value: 0 },
                ],
            }
        },
        {
            label: 'Бооцоо хаагдах огноо',
            name: 'eDate',
            type: 'date',
            rules: [{required: true, message: 'Бооцоо хаагдах огноо оруулна уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        ]
    }