import { createBrowserRouter } from "react-router-dom";
import { Register, Login } from 'modules/public';
import adminRoutes from './adminRoutes';
import { ProtectedRoute } from 'components';
import RootPage from "modules/core";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    errorElement: <div>error</div>,
    children: [...adminRoutes].map(foo => {
      return {
        ...foo,
        element: <ProtectedRoute routeItem={foo}>
          {foo.element}
        </ProtectedRoute>
      }
    })
  },
  {
    path: '/user/login',
    element: <Login></Login>
  },
  {
    path: '/user/register',
    element: <Register></Register>
  }
])

export default router
