import React from 'react';
import PropTypes from 'prop-types';
import GridItem from './GridItem';

const GridList = ({right, left, data, columns}) => {
  return (
    <div className="flex flex-col relative">
      <div className="flex mb-10">
        <div className="flex-1 flex gap-2">
          <span className="border-primary border text-primary h-full rounded-lg px-4 py-2">
            Нийт: {data.length}
          </span>
          { left }
        </div>  
        { right }
      </div>
      <div className="flex flex-col gap-6">
        {
          data.map((row, rowIndex) => {
            return (
              <GridItem key={`grid-item-${rowIndex}`} data={row} columns={columns} rowIndex={rowIndex}>

              </GridItem>
            )
          })
        }
      </div>
    </div>
  );
}

GridList.propTypes = {
  data: PropTypes.array
}

GridList.defaultProps = {
  data: []
}

export default GridList;
