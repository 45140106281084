import {
    Table,
    Button,
    Modal,
    Form as AntForm,
    Menu,
    Dropdown,
  } from 'antd';
  import { useState, useEffect } from 'react';
  import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    MoreOutlined,
    WarningOutlined,
  } from '@ant-design/icons';
  import { instance } from 'utils/axios';
  import { Form } from 'components';
  import fields from './form';
  import nullImage from '../../../assets/images/download.png';
  function Category() {
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [form] = AntForm.useForm();
    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = () => {
      setLoading(true)
      instance({
        method: 'get',
        url: 'category',
      }).then((res) => {
        setData(res.data);
      }).catch((err) => {
  
      }).then(() => {
        setLoading(false)
      })
    };
  
    const columns = [
      {
        title: '',
        dataIndex: 'No',
        key: 'No',
        width: 50,
        render: (text, row, i) => <span>{i + 1}</span>,
      },
      {
        title: 'Нэр',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Үйлдэл',
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render: (text, row) => (
          <Dropdown
          placement="bottomRight"
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleEdit(row)} key="action-1">
                <div className="flex items-center gap-2">
                  <EditOutlined /> Засах
                </div>
              </Menu.Item>
              <Menu.Item
                danger
                className="flex items-center gap-2"
                onClick={() => handleDelete(row.id)}
                key="action-2" 
              >
                <div className="flex items-center gap-2">
                  <DeleteOutlined /> Устгах
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button className="flex items-center">
            <MoreOutlined />
          </Button>
        </Dropdown>
        ),
      },
    ];

    const handleDelete = (id) => {
      modal.confirm({
        icon: <WarningOutlined style={{color: 'red'}}/>,
        title: 'Анхаарна уу !',
        content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
        okText: 'Устгах',
        cancelText: 'Буцах',
        onOk() {
          return new Promise((resolve, reject) => {
            instance({
              method: 'delete',
              url: `category/${id}`
            }).then((res) => {
              resolve()
            }).catch((err) => {
              reject()
            }).then(() => fetchData())
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    }
  
    const handleSubmit = (values) => {
      setLoading(true)
      if(selectedData){
          instance({
              method: 'put',
              url: 'category',
              data: {
              ...values,
              id: selectedData.id,
              }
          }).then(() => {
              fetchData()
              handleCloseModal()
          }).catch((err) => {
              
          }).then(() => setLoading(false))
          }
          else{
          instance({
              method: 'post',
              url: 'category',
              data: {
              ...values,
              }
          }).then(() => {
              fetchData()
              handleCloseModal()
          }).catch((err) => {
          }).then(() => setLoading(false))
      }
  }

    const handleEdit = (row) => {
      setSelectedData(row);
      setIsEdit(false);
      setOpenModal(true);
    };
  
    const handleAddNew = () => {
      setSelectedData(null);
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setSelectedData(null);
      setOpenModal(false);
      form.resetFields();
      setIsEdit(true);
    };
  
    return (
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-center bg-white px-8 py-4">
          <button className="menu-button text-primary">Ангилал</button>
        </div>
        <div className="mx-8">
          <div className="flex justify-end mb-6">
            <Button
              size="large"
              type="primary"
              className="flex items-center"
              icon={<PlusOutlined className="text-base" />}
              onClick={handleAddNew}
            >
              Нэмэх
            </Button>
          </div>
          <Table
            rowKey={(row) => row.key}
            dataSource={data}
            columns={columns}
            loading={loading}
          />
          <Modal
            title={
              <div className="text-lg uppercase">
                {selectedData ? 'Засах' : 'Нэмэх'}
              </div>
            }
            open={openModal}
            onCancel={handleCloseModal}
            width={900}
            destroyOnClose
            footer={false}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={[]}
              editData={selectedData}
              isEdit={isEdit}
              fields={fields()}
              disabled={!isEdit}
              className='gap-5'
            />
            {selectedData ? (
              <div className="flex justify-end mt-5">
                {isEdit ? (
                  <div className="flex items-center gap-4">
                    <Button type="primary" onClick={() => form.submit()} loading={loading}>
                      Хадгалах
                    </Button>
                    <Button onClick={() => setIsEdit(false)}>Цуцлах</Button>
                  </div>
                ) : (
                  <Button
                    type="primary"
                    className="border-none bg-sent border-sent hover:bg-sent hover:brightness-110 flex items-center"
                    onClick={() => setIsEdit(true)}
                  >
                    <EditOutlined />
                    Засварлах
                  </Button>
                )}
              </div>
            ) : (
              <div className="flex justify-end mt-5">
                <Button type="primary" onClick={() => form.submit()} loading={loading}>
                  Хадгалах
                </Button>
              </div>
            )}
          </Modal>
          {contextHolder}
        </div>
      </div>
    );
  }
  
  export default Category;
  