import { Table, Button, Modal, Form as AntForm, Input, Upload, Menu, Dropdown, Tag, Space, Switch, List, Tooltip} from 'antd'
import { useState, useEffect, useRef } from 'react'
import { PlusOutlined, SendOutlined, LeftOutlined, SearchOutlined, DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios'
import fields from './form'
import { useNavigate, useParams } from 'react-router-dom'

function MessengeSend() {
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ modal, contextHolder ] = Modal.useModal();
    const [selectionType, setSelectionType] = useState('checkbox');
    const [selectedRows, setSelectedRows] = useState([]);
    const {id} = useParams();
    const [searchText, setSearchText] = useState(''); //
    const [searchedColumn, setSearchedColumn] = useState(''); // 
    const searchInput = useRef(null);  //
    const navigate = useNavigate()
    const [ form ] = AntForm.useForm()
    useEffect(() => {
        fetchData()
    },[])

    const fetchData = () => {
        setLoading(true)
        instance({
        method: 'get',
        url: `/participant/disabled/${id}`
        }).then((res) => {
        setData(res.data)
        }).catch((err) => {
        }).then(() => setLoading(false))
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Хайх
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Арилгах
            </Button>
           
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Хаах
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    });
    const columns = [
        // {
        //     title: '',
        //     dataIndex: 'No',
        //     key: 'No',
        //     width: 50,
        //     render: (text, row, i) => (
        //         <span>{i+1}</span>
        //     )
        // },
        {
          title: 'Phone Number',
          dataIndex: ['player', 'phoneNumber'],
          key: 'phoneNumber',
          ...getColumnSearchProps('player', 'phoneNumber'),
        },
        
      ];
    
    
    const handleDelete = (id) => {
        modal.confirm({
        icon: <WarningOutlined style={{color: 'red'}}/>,
        title: 'Анхаарна уу !',
        content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
        okText: 'Устгах',
        cancelText: 'Буцах',
        onOk() {
            return new Promise((resolve, reject) => {
            instance({
                method: 'delete',
                url: `banner/${id}`
            }).then((res) => {
                resolve()
            }).catch((err) => {
                reject()
            }).then(() => fetchData())
            }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
        });
    };
    const handleSubmit = () => {
        setLoading(true);
        instance({
          method: 'post',
          url: '/participant/disabled',
          headers: { 'Content-Type': 'application/json' },
          data: selectedRows.map(foo => ({betId: foo.bet?.id, phoneNumber: foo.player?.phoneNumber}))
        }).then(() => {
          fetchData();
        }).catch((err) => {
        }).then(() => setLoading(false));
      };
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys`, selectedRowKeys);
          setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      };
return (
        <div className='flex flex-col gap-6'>
         <div className='flex justify-start items-center gap-4 bg-white px-8 py-[13px]'>
        <button className='menu-button text-primary flex items-center hover:bg-slate-100 p-2 rounded-full' onClick={() => navigate(-1)}>
        <LeftOutlined />
        </button>
        <button className='menu-button text-primary'>
          SMS илгээх
        </button>
        </div>
        <div className='mx-8'>
        <Button
                size='middle'
                type='primary'
                className='mb-4'
                icon={<SendOutlined className='text-base'/>}
                onClick={handleSubmit}
              >Илгээх</Button>
        <Table
            rowKey={(row) => row.bet?.id}
            rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
            pagination={{
                pageSizeOptions: ['20', '50', '100'],
                showSizeChanger: true,
              }}
            dataSource={data}
            columns={columns}
            
            expandable={{
                expandedRowRender: (role) => (
                <div>
                    <List
                    bordered
                    dataSource={role.bet?.bet_details}
                    renderItem={(item, i) => (
                        <List.Item key={i}>
                            <div className='flex flex-row gap-4'>
                            <div><div className='text-gray-600'>Нэр</div><div>{item.participant?.name}</div></div>
                            <div>
                                {
                                item.participant?.type === 1 ?
                                    <>
                                    <div className='text-gray-600'>Аймаг</div><div>{item.participant?.province}</div>
                                    </>
                                    : 
                                    <>
                                    <div className='text-gray-600 w-[220px]'>Цол</div><div>{item.participant?.rank}</div>
                                    </>
                                }
                            </div>
                            <div>
                                <div className='text-gray-600'>Зураг</div>
                                <div>
                                <img src={`${process.env.REACT_APP_CDN_URL}${item.participant?.image}`} width={60} alt={`parti-${i}`}/>
                                </div>
                                </div>
                            </div>
                            <div></div>
                        </List.Item>
                    )}
                    />
                </div>
                ),
                rowExpandable: (record) => record.name !== 'Дата алга',
            }}
            />


        </div>
        {
            contextHolder
        }
        </div>
    )
}

export default MessengeSend