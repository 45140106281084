export default () => {
    return [
        {
            label: 'Гарчиг',
            name: 'title',
            rules: [{required: true, message: 'Гарчиг оруулна уу!'}],
            className: 'col-span-12 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Дэлгэрэнгүй',
            name: 'description',
            type: 'textarea',
            rules: [{required: true, message: 'Дэлгэрэнгүй оруулна уу!'}],
            className: 'col-span-12 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Мэдээ',
            name: 'content',
            type: 'editor',
            rules: [{required: true, message: 'Мэдээ оруулна уу!'}],
            className: 'col-span-12 mb-0',
            inputProps: {   
            className: 'w-full',
            }
        },
        {
            label: 'Төлөв',
            name: 'isActive',
            type: 'select',
            rules: [{required: true, message: 'Төлөв сонгоно уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            options: [
                { label: 'Идэвхтэй', value: 1 },
                { label: 'Идэвхгүй', value: 0 },
            ],
            }
        },
        {
            label: 'Зураг',
            name: 'image',
            type: 'file',
            rules: [{required: true, message: 'Зураг сонгоно уу!'}],
            className: 'col-span-6 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        ]
    }