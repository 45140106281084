import { Form, Input } from "antd";
const { TextArea } = Input;

export default () => {
    return [
        {
            label: 'Нэр',
            name: 'name',
            rules: [{required: true, message: 'Нэр оруулна уу!'}],
            className: 'col-span-3 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Нийт сонгож болох тоо',
            name: 'pCount',
            type: 'number',
            rules: [{required: true, message: 'Нийт сонгож болох тоо оруулна уу!'}],
            className: 'col-span-3 mb-0',
            inputProps: {
                className: 'w-full',
            }
        },
        {
            label: 'Бооцоо хаагдах огноо',
            name: 'eDate',
            type: 'date',
            rules: [{required: true, message: 'Бооцоо хаагдах огноо оруулна уу!'}],
            className: 'col-span-3 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Бооцоо эхлэх огноо',
            name: 'sDate',
            type: 'date',
            rules: [{required: true, message: 'Бооцоо эхлэх огноо оруулна уу!'}],
            className: 'col-span-3 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Шагналын сангийн тайлбар',
            name: 'question',
            type: 'textarea',
            className: 'col-span-12 mb-0',
            inputProps: {
            className: 'w-full',
            }
        },
        {
            label: 'Төрөл',
            name: 'type',
            type: 'select',
            rules: [{required: true, message: 'Төрөл сонгоно уу!'}],
            className: 'col-span-4 mb-0',
            inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Шөвгийн найм', value: 0 },
                    { label: 'Бөх VS Бөх', value: 1 },
                    { label: 'Таавар', value: 2 },
                ],
            }
        },
        {
            label: 'Бооцоо бүртгэх эсэх',
            name: 'status',
            type: 'select',
            rules: [{required: true, message: 'Бооцоо бүртгэх эсэх сонгоно уу!'}],
            className: 'col-span-4 mb-0',
            inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Идэвхтэй', value: 1 },
                    { label: 'Идэвхгүй', value: 0 },
                ],
            }
        },
        {
            label: 'Засвар зөвшөөрөх',
            name: 'isEditable',
            type: 'select',
            rules: [{required: true, message: 'Засвар зөвшөөрөх эсэх сонгоно уу!'}],
            className: 'col-span-4 mb-0',
            inputProps: {
                className: 'w-full',
                options: [
                    { label: 'Идэвхтэй', value: 1 },
                    { label: 'Идэвхгүй', value: 0 },
                ],
            }
        },
        {
                type: 'component',
                component: <>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
                    {({ getFieldValue }) => {
                        return (
                            <>
                                {
                                    getFieldValue('type') == 2 ?
                                        <Form.Item
                                            label="Асуулт"
                                            name="answer"
                                            className="col-span-12 mb-0"
                                            rules={[{required: true, message: 'Асуулт оруулна уу!'}]}
                                            >
                                                <TextArea type="number"/>
                                        </Form.Item>
                                        : <></>
                                }
                            </>
                        )
                    }}
                </Form.Item>
                </>
            },
        ]
    }