import React from 'react';
import { AuthContext } from 'contexts';
import menu from '../../menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';

const Sidebar = ({}) => {
  const {state} = React.useContext(AuthContext)
  
  const renderItem = (route, i) => {
    return (
      <>
        {
          route.type === 'sub' ? 
          <Menu.SubMenu  key={`menu-item-${i}`} icon={route.icon} title={route.name}>
            {
              route.children.map((item, index) => (
                <Menu.Item icon={item.icon} key={`menu-item-${i}-sub-${index}`}>
                  <Link to={item.path} >
                    {item.name}
                  </Link>
                </Menu.Item>
              ))
            }
          </Menu.SubMenu>
          :
          route.type === 'group' ?
          <Menu.ItemGroup key={`menu-item-${i}`} title={<div className='text-center'>{route.name}</div>}>
            {
              route.children.map((item, index) => (
                <>
                  {
                    item.children ?
                    <Menu.SubMenu key={`menu-item-${i}-group-${index}`} icon={item.icon} title={item.name}>
                      {
                        item.children.map((childItem, childIndex) => (
                          <Menu.Item icon={childItem.icon} key={`menu-item-${i}-group-${index}-${childIndex}`}>
                            <Link to={childItem.path}>
                              {childItem.name}
                            </Link>
                          </Menu.Item>
                        ))
                      }
                    </Menu.SubMenu>
                    :
                    <Menu.Item key={`menu-item-${i}-group-${index}`} icon={item.icon}>
                      <Link to={item.path}>
                        {item.name}
                      </Link>
                    </Menu.Item>
                  }
                </>
              ))
            }
          </Menu.ItemGroup>
          :
          <Menu.Item key={`menu-item-${i}`} icon={route.icon}>
            {
              route.link ? 
              <a href={route.link} target={route.target}>{route.name}</a>
              :
              <Link to={route.path}>
                {route.name}
              </Link>
            } 
          </Menu.Item>
        }
      </>
    )
  }
  
  return (
    <Menu
    defaultSelectedKeys={['1']}
    mode={'inline'}
    defaultOpenKeys={['menu-item-0']}
    >
      {
        state.token &&
        menu.map((route, i) => (
          renderItem(route,i)
        ))
      }
    </Menu>

  );
}

export default Sidebar;
