import {
    Table,
    Button,
    Modal,
    Form as AntForm,
    Menu,
    Dropdown,
  } from 'antd';
  import { useState, useEffect } from 'react';
  import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    MoreOutlined,
    WarningOutlined,
  } from '@ant-design/icons';
  import { instance } from 'utils/axios';
  import { Form } from 'components';
  import nullImage from '../../../assets/images/download.png';

  
const fields = [
  {
    label: '',
    name: 'content',
    type: 'editor',
    rules: [{required: true, message: 'Бичвэр оруулна уу!'}],
    className: 'col-span-12 mb-0',
    inputProps: {
      className: 'w-full',
      height: '560px',
      rows: 25
    }
  },
]
  function Policy() {
    const [ isEdit, setIsEdit ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ form ] = AntForm.useForm()
    const [data, setData] = useState([]);

    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = () => {
      setLoading(true)
      instance({
        method: 'get',
        url: 'policy',
      }).then((res) => {
        setData(res.data);
      }).catch((err) => {
  
      }).then(() => {
        setLoading(false)
      })
    };
    const handleSubmit = (values) => {
      setLoading(true)
      instance({
        method: 'put',
        url: 'policy',
        data: {
          ...values,
          id: 1,
        }
      }).then(() => {
        fetchData()
        form.resetFields()
        setIsEdit(false)
      }).catch((err) => {
        
      }).then(() => setLoading(false))
    }  
  
    return (
      <div className="flex flex-col gap-6 ">
        <div className="flex justify-between items-center bg-white px-8 py-4">
          <button className="menu-button text-primary">Журам</button>
        </div>
        <div className='p-4 mx-auto max-w-[1280px]'>
      {
        isEdit ? 
        <Form 
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={[]}
          editData={{content: data.content}}
          isEdit={isEdit}
          fields={fields}
          disabled={!isEdit}
          className='gap-5'
        />
        :
        <div dangerouslySetInnerHTML={{ __html: data.content }} className='w-full'></div>
      }
      <div className='flex justify-end mt-5'>
        {
          isEdit ?
          <div className='flex items-center gap-4'>
            <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
            <Button onClick={() => setIsEdit(false)}>Цуцлах</Button>
          </div>
          :
          <Button 
            type='primary' 
            className='border-none bg-sent border-sent hover:bg-sent hover:brightness-110 flex items-center' 
            onClick={() => setIsEdit(true)}
          >
            <EditOutlined/>
            Засварлах
          </Button>
        }
      </div>
    </div>
      </div>
    );
  }
  
  export default Policy;
  